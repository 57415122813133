:root {
  --primary-11: #02090b;
  --primary-10: #041e23;
  --primary-9: #07363e;
  --primary-8: #0a5561;
  --primary-7: #0d6675;
  --primary-6: #107e90;
  --primary-5: #3693a2;
  --primary-4: #62aab6;
  --primary-3: #8ac0c9;
  --primary-2: #b7d8dd;
  --primary-1: #e2eff1;

  --primary-color: var(--primary-6); // color used in main actions

  --text-color: black;
  --input-focus-shadow-color: #07363e; // outer shadow of input on focus - GIVE 20% ALPHA

  --background-color: #f3f3f3;

  --menu-text-color: black;
  --menu-item-active-bg: #b7d8dd; // color of the side menu active ite
  --menu-bg: #f4f9fa; // color of the background of the menu
  --menu-sub-bg: #ffffff; // color of the background of the submenu

  --login-background-color: var(--primary-10);

  --header-logo-background: var(--primary-9);
  --header-background: var(--primary-color);
  --header-text-color: #fff;

  --footer-text-color: #fff;
  --footer-color: var(--primary-5);
}

@primary-11: #02090b;
@primary-10: #041e23;
@primary-9: #07363e;
@primary-8: #0a5561;
@primary-7: #0d6675;
@primary-6: #107e90;
@primary-5: #3693a2;
@primary-4: #62aab6;
@primary-3: #8ac0c9;
@primary-2: #b7d8dd;
@primary-1: #e2eff1;

@primary-color: #107e90;
@component-background: #ffffff;
@background-color-base: #ffffff;